import { ActionTree, MutationTree } from "vuex";

import obmed from "@/services/obmed";
import viaCEP from "@/services/viacep";

import { RootState } from "@/store/types";
import {
  LocalityActions,
  LocalityCity,
  LocalityMutations,
  LocalitySearch,
  LocalityState,
  LocalityState_,
} from "@/store/locality/types";

const { VUE_APP_API_URL, VUE_APP_VIACEP_API } = process.env;

const endpoints = {
  city: "cidade/",
  state: "estado/",
  searchCEP: (cep: string) => `ws/${cep.replaceAll("-", "")}/json`,
};

const state: LocalityState_ = {
  allStates: [],
  allCities: [],
};

const mutations: MutationTree<LocalityState_> & LocalityMutations = {
  setAllStates(state, states) {
    state.allStates = states;
  },
  setAllCities(state, cities) {
    state.allCities = cities;
  },
};

const actions: ActionTree<LocalityState_, RootState> & LocalityActions = {
  async getStates({ commit, dispatch }) {
    try {
      const response = await obmed.get<LocalityState[]>({
        entity: endpoints.state,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setAllStates", response?.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchCity({ commit, dispatch }, payload) {
    try {
      const response = await obmed.get<LocalityCity[]>({
        entity: endpoints.city,
        config: { query: { ds_uf: payload.ds_uf }, baseURL: VUE_APP_API_URL },
      });
      commit("setAllCities", response?.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchCEP({ dispatch }, payload) {
    try {
      const response = await viaCEP.get<LocalitySearch>({
        entity: endpoints.searchCEP(payload.cep),
        config: { baseURL: VUE_APP_VIACEP_API },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

export const LocalityStore = { state, mutations, actions };
