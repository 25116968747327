import obmed, { ObmedCancelRequest } from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  CartState,
  CartLocaleState,
  CartLocaleMutations,
  CartLocaleActions,
  CartLocaleGetters,
  CartStructure,
  ProcedureCartForm,
  ConsultationCartForm,
  PayCartForm,
} from "@/store/cart/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "parceiro-carrinho-de-compra/?novo=true",
  consultationByID: (_id: number | string) => `parceiro-reservar-horario/${_id}/`,
  examByID: (_id: number | string) => `parceiro-reservar-horario-exame/${_id}/`,
  payment: (_id: number[] | string) => `horario-agendamento/?cd_horario=${_id}`,
  pay: "parceiro-pagamento/",
  pix: "pagamento-pix/",
};

const cancelRequests: { [key: string]: ObmedCancelRequest | null } = {
  cart: null,
};

const state: CartLocaleState = {
  current: null,
  transaction: null,
  pix: null,
  list: null,
};

const getters: GetterTree<CartState, RootState> & CartLocaleGetters = {};

const mutations: MutationTree<CartState> & CartLocaleMutations = {
  setCurrentCart(state, cart) {
    state.current = cart;
  },
  setCartList(state, cart) {
    state.list = cart;
  },
  setCartTransaction(state, transaction) {
    state.transaction = transaction;
  },
  setPix(state, pix) {
    state.pix = pix;
  },
};

const actions: ActionTree<CartState, RootState> & CartLocaleActions = {
  async getCartList({ commit, dispatch }) {
    try {
      if (cancelRequests.cart) cancelRequests.cart.cancel();
      cancelRequests.cart = obmed.getCancelToken();

      const response = await obmed.get<CartStructure>({
        entity: endpoints.root,
        config: { cancelToken: cancelRequests.cart?.token, baseURL: VUE_APP_API_URL },
      });
      commit("setCartList", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async addConsultationToCart({ dispatch }, payload) {
    try {
      const response = await obmed.patch<{ cd_checkup: string | null; nm_usuario_edi: string }, ConsultationCartForm>({
        entity: endpoints.consultationByID(payload._id),
        data: { ...payload.form, ie_reservar: "R" },
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async removeConsultationFromCart({ dispatch }, payload) {
    try {
      const response = await obmed.patch<{ cd_checkup: string | null; nm_usuario_edi: string }, ConsultationCartForm>({
        entity: endpoints.consultationByID(payload._id),
        data: { ...payload.form, ie_reservar: "L" },
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async addProcedureToCart({ dispatch }, payload) {
    try {
      const response = await obmed.patch<{ cd_checkup: string | null; nm_usuario_edi: string }, ProcedureCartForm>({
        entity: endpoints.examByID(payload._id),
        data: { ...payload.form, ie_reservar: "R" },
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async removeProcedureFromCart({ dispatch }, payload) {
    try {
      const response = await obmed.patch<{ cd_checkup: string | null; nm_usuario_edi: string }, ProcedureCartForm>({
        entity: endpoints.examByID(payload._id),
        data: { ...payload.form, ie_reservar: "L" },
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async payCart({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<{ detail?: string; status?: number; body?: any }, PayCartForm>({
        entity: form.forma_pagamento === "credit_card" ? endpoints.pay : endpoints.pix,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error: any) {
      if (form.forma_pagamento === "boleto" && error.status === 400) {
        commit("setCartTransaction", error?.responseData?.transaction);
        commit("addToast", { summary: "Boleto gerado com sucesso!", severity: "success" });
      } else if (error?.responseData.status_code === 200) return error.responseData;
      else dispatch("handleError", error);
    }
  },
  async consultPayment({ dispatch }, cd_time) {
    try {
      const response = await obmed.get<{ pago: boolean }>({
        entity: endpoints.payment(cd_time),
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const CartStore = { state, getters, modules, mutations, actions };
