import { AxiosInstance } from "axios";

import formatViaCEPError from "@/services/viacep/errors";
import { addQuery, configRequestDefs } from "@/services/viacep/utils";

import { ViaCEPRequestConfig } from "@/services/viacep/types";

export function configResponse(instance: AxiosInstance) {
  instance.interceptors.response.use(
    async (response) => (response?.data?.erro ? Promise.reject(await formatViaCEPError({ response })) : response),
    async (error) => Promise.reject(await formatViaCEPError(error))
  );
}

export function configRequest(instance: AxiosInstance) {
  instance.interceptors.request.use(
    (config: ViaCEPRequestConfig) => {
      if (config.query) return addQuery(configRequestDefs(config));
      return configRequestDefs(config);
    },
    (error) => {
      const DEBUG = process.env.NODE_ENV === "development";
      if (DEBUG) console.error(error);

      return Promise.reject(error);
    }
  );
}

export function configInterceptors(instance: AxiosInstance) {
  configRequest(instance);
  configResponse(instance);

  return instance;
}

export default configInterceptors;
