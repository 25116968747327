export const localeConfig = {
  firstDayOfWeek: 0,
  dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
  dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
  dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
  monthNames: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
  today: "Hoje",
  clear: "Limpar",
  dateFormat: "dd/mm/yy",
  weekHeader: "Sem",
  startsWith: "Começa com",
  contains: "Contém",
  notContains: "Não contém",
  endsWith: "Termina com",
  equals: "É igual",
  notEquals: "Não é igual",
  noFilter: "Sem filtro",
  lt: "Menor que",
  lte: "Menos que ou igual a",
  gt: "Maior que",
  gte: "Melhor que ou igual a",
  dateIs: "Data é",
  dateIsNot: "Data não é",
  dateBefore: "A data é antes",
  dateAfter: "A data é depois",
  apply: "Aplicar",
  matchAll: "Combinar tudo",
  matchAny: "Corresponder a qualquer",
  addRule: "Adicionar regra",
  removeRule: "Remover regra",
  accept: "Sim",
  reject: "Não",
  choose: "Escolher",
  upload: "Enviar",
  cancel: "Cancelar",
  weak: "Fraco",
  medium: "Médio",
  strong: "Forte",
  passwordPrompt: "Entre com uma senha",
  emptyFilterMessage: "Nenhum resultado encontrado",
  emptyMessage: "Sem opções disponíveis",
};
