import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ViaCEPParams, ViaCEPPromise } from "@/services/viacep/types";

import configInterceptors from "@/services/viacep/interceptors";

export class ViaCEPService {
  private http: AxiosInstance;

  constructor(config: AxiosRequestConfig = {}) {
    if (!config.timeout) config.timeout = 40000;
    this.http = configInterceptors(axios.create(config));
  }

  public get<T>(params: ViaCEPParams): ViaCEPPromise<T> {
    const response = this.http.get<T, AxiosResponse<T>>(params.entity, params.config);

    return response;
  }

  public post<T, D = T>(params: ViaCEPParams<D>): ViaCEPPromise<T> {
    const response = this.http.post<T, AxiosResponse<T>>(params.entity, params.data, params.config);

    return response;
  }

  public patch<T, D = T>(params: ViaCEPParams<D>): ViaCEPPromise<T> {
    const response = this.http.patch<T, AxiosResponse<T>>(params.entity, params.data, params.config);

    return response;
  }

  public delete<T>(params: ViaCEPParams): ViaCEPPromise<T> {
    const response = this.http.delete<T, AxiosResponse<T>>(params.entity, params.config);

    return response;
  }

  public getCancelToken() {
    return axios.CancelToken.source();
  }
}

export default new ViaCEPService();
