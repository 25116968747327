import obmed, { ObmedCancelRequest } from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Procedure,
  ProcedureState,
  ProcedureLocaleState,
  ProcedureLocaleMutations,
  ProcedureLocaleActions,
  ProcedureLocaleGetters,
} from "@/store/procedure/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "consulta-exame-procedimento-cirurgia/?ie_tipo_procedimento=PP&group=1",
};

const cancelRequests: { [key: string]: ObmedCancelRequest | null } = {
  search: null,
};

const state: ProcedureLocaleState = {
  list: [],
};

const getters: GetterTree<ProcedureState, RootState> & ProcedureLocaleGetters = {};

const mutations: MutationTree<ProcedureState> & ProcedureLocaleMutations = {
  setAllProcedure(state, procedure) {
    state.list = procedure;
  },
};

const actions: ActionTree<ProcedureState, RootState> & ProcedureLocaleActions = {
  async searchProcedure({ commit, dispatch }, { search }) {
    try {
      if (cancelRequests.search) cancelRequests.search.cancel();
      cancelRequests.search = obmed.getCancelToken();

      const response = await obmed.get<Procedure[]>({
        entity: endpoints.root,
        config: {
          query: { nm_procedimento: search },
          cancelToken: cancelRequests.search?.token,
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setAllProcedure", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const ProcedureStore = { state, getters, mutations, actions, modules };
