import obmed, { ObmedCancelRequest } from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { PaginationParams, PaginationResponse, RootState } from "@/store/types";
import {
  Budget,
  BudgetState,
  BudgetLocaleState,
  BudgetLocaleMutations,
  BudgetLocaleActions,
  BudgetLocaleGetters,
} from "@/store/budget/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "orcamento/",
};

const cancelRequests: { [key: string]: ObmedCancelRequest | null } = {
  search: null,
};

const state: BudgetLocaleState = {
  current: null,
  list: {},
  pagination: { page: 1, total: 1, items: 0, perPage: 12 },
};

const getters: GetterTree<BudgetState, RootState> & BudgetLocaleGetters = {
  budgetList(state) {
    return Object.keys(state.list).reduce<Budget[]>((list, page) => [...list, ...state.list[page]], []);
  },
};

const mutations: MutationTree<BudgetState> & BudgetLocaleMutations = {
  setAllBudget(state, budgets) {
    const result: { [key: string]: Budget[] } = { 1: [] };

    let currentPage = 1;
    for (const budget of budgets) {
      if (result[currentPage].length < 12) result[currentPage].push(budget);
      else {
        currentPage += 1;
        result[currentPage] = [budget];
      }
    }

    state.list = result;
  },
  clearAllBudget(state) {
    state.list = {};
  },
  setBudgetPagination(state, pagination) {
    state.pagination = pagination;
  },
  setBudgetPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<BudgetState, RootState> & BudgetLocaleActions = {
  async searchBudget({ commit, dispatch }, { search, type, procedureIds, isPaginated }) {
    try {
      if (cancelRequests.search) cancelRequests.search.cancel();
      cancelRequests.search = obmed.getCancelToken();

      const pagination: PaginationParams = {
        page: state.pagination.page,
        per_page: state.pagination.perPage,
        paginacao: Boolean(isPaginated),
      };
      const cd_procedimento = procedureIds?.map((item) => item.toString().replace(/\D/g, ""));

      const response = await obmed.get<PaginationResponse<Budget>>({
        entity: endpoints.root,
        config: {
          query: { nm_procedimento: search, ie_tipo: type, cd_procedimento, ...pagination },
          cancelToken: cancelRequests.search?.token,
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setAllBudget", response.data.result);
      commit("setBudgetPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error: any) {
      if (!error?.isObmedError) dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const BudgetStore = { state, getters, modules, mutations, actions };
