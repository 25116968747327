import { ActionTree, GetterTree, MutationTree } from "vuex";
import obmed from "@/services/obmed";

import { RootState } from "@/store/types";
import { ObmedError } from "@/services/obmed/types";
import {
  SessionActions,
  SessionGetters,
  SessionMutations,
  SessionState,
  SessionToken,
} from "@/store/user/session/types";

const { VUE_APP_AUTH_API_URL } = process.env;

const endpoints = {
  getToken: "authentication/token/",
};

const state: SessionState = {
  token: localStorage.getItem("TOKEN") || "",
};

const getters: GetterTree<SessionState, RootState> & SessionGetters = {
  isLogged(state) {
    return Boolean(state.token);
  },
};

const mutations: MutationTree<SessionState> & SessionMutations = {
  setToken(state, token) {
    if (token) {
      state.token = token;
      localStorage.setItem("TOKEN", token);
    } else {
      const ACCEPT_COOKIES = localStorage.getItem("ACCEPT_COOKIES");

      state.token = "";
      localStorage.clear();

      if (ACCEPT_COOKIES) localStorage.setItem("ACCEPT_COOKIES", ACCEPT_COOKIES);
    }
  },
};

const actions: ActionTree<SessionState, RootState> & SessionActions = {
  async createSession({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<SessionToken, any>({
        entity: endpoints.getToken,
        data: form,
        config: { baseURL: VUE_APP_AUTH_API_URL, useCompany: false },
      });
      commit("setToken", response.data.token);

      return response;
    } catch (error: any) {
      if (error.isObmedError) {
        const obmedError: ObmedError = error;
        obmedError.messages = [{ summary: "Usuário ou Senha Inválido!", severity: "error" }];

        dispatch("handleError", obmedError);
      }
      dispatch("handleError", error);
    }
  },
  async deleteSession({ commit, dispatch }) {
    try {
      commit("setToken");
      commit("setCurrentUser", null);

      return { status: 200 };
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

export const SessionStore = { state, mutations, getters, actions };
