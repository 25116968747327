import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Specialty,
  SpecialtyState,
  SpecialtyLocaleState,
  SpecialtyLocaleMutations,
  SpecialtyLocaleActions,
  SpecialtyLocaleGetters,
} from "@/store/specialty/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "consulta-exame-procedimento-cirurgia/?ie_tipo_procedimento=CO&ie_telemedicina=false&group=1",
};

const state: SpecialtyLocaleState = {
  list: [],
};

const getters: GetterTree<SpecialtyState, RootState> & SpecialtyLocaleGetters = {};

const mutations: MutationTree<SpecialtyState> & SpecialtyLocaleMutations = {
  setAllSpecialty(state, specialty) {
    state.list = specialty;
  },
};

const actions: ActionTree<SpecialtyState, RootState> & SpecialtyLocaleActions = {
  async getAllSpecialty({ commit, dispatch }) {
    try {
      const response = await obmed.get<{ [key: number]: any[] }>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL },
      });

      const specialtyList: Specialty[] = Object.keys(response.data).reduce(
        (acumulador: any[], especialidadeId) => [
          { ...(response.data as any)[especialidadeId][0], id: especialidadeId },
          ...acumulador,
        ],
        []
      );

      commit("setAllSpecialty", specialtyList);

      return { ...response, data: specialtyList };
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const SpecialtyStore = { state, getters, modules, mutations, actions };
