import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  CreditCardState,
  CreditCardLocaleState,
  CreditCardLocaleMutations,
  CreditCardLocaleActions,
  CreditCardLocaleGetters,
} from "@/store/credit-card/types";

const state: CreditCardLocaleState = {
  form: null,
};

const getters: GetterTree<CreditCardState, RootState> & CreditCardLocaleGetters = {};

const mutations: MutationTree<CreditCardState> & CreditCardLocaleMutations = {
  setCreditCardForm(state, form) {
    state.form = form;
  },
};

const actions: ActionTree<CreditCardState, RootState> & CreditCardLocaleActions = {};

const modules: ModuleTree<RootState> = {};

export const CreditCardStore = { state, getters, modules, mutations, actions };
