import { ViaCEPRequestConfig } from "@/services/viacep/types";

export function formatQuery(items: { [key: string]: any }) {
  for (const key in items) {
    const item = items[key];

    if (Array.isArray(item)) {
      items[key] = item.join(",");
    }

    if (!items[key]) {
      delete items[key];
    }
  }

  return items;
}

export function addQuery(config: ViaCEPRequestConfig = {}) {
  config.params = {
    ...(config.params || {}),
    ...formatQuery(config.query || {}),
  };

  return config;
}

export function configRequestDefs(config: ViaCEPRequestConfig = {}) {
  config.baseURL = config.baseURL ?? "";
  config.headers = config.headers || {};
  config.params = config.params || {};

  return config;
}
