import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Specialist,
  SpecialistState,
  SpecialistLocaleState,
  SpecialistLocaleMutations,
  SpecialistLocaleActions,
  SpecialistLocaleGetters,
} from "@/store/specialist/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "especialista-com-suas-especialidades/?ie_telemedicina=false",
  byID: (specialtyID: number, establishmentID: number) =>
    `especialista-agrupado-por-data/?cd_especialidade=${specialtyID}&ie_telemedicina=false&cd_estabelecimento=${establishmentID}`,
  dateByID: (specialtyID: number, establishmentID: number) =>
    `especialista-por-especialidade-e-estabelecimento/?cd_especialidade=${specialtyID}&ie_telemedicina=false&cd_estabelecimento=${establishmentID}`,
};

const state: SpecialistLocaleState = {
  list: [],
  dateList: {},
  dates: [],
};

const getters: GetterTree<SpecialistState, RootState> & SpecialistLocaleGetters = {};

const mutations: MutationTree<SpecialistState> & SpecialistLocaleMutations = {
  setAllSpecialist(state, specialist) {
    state.list = specialist;
  },
  setAllSpecialistByDate(state, specialist) {
    state.dateList = specialist;
  },
  setAllSpecialistDate(state, dates) {
    state.dates = dates;
  },
};

const actions: ActionTree<SpecialistState, RootState> & SpecialistLocaleActions = {
  async getAllSpecialist({ commit, dispatch }, { ie_sexo, ie_ordem_alfabetica } = {}) {
    try {
      const response = await obmed.get<Specialist[]>({
        entity: endpoints.root,
        config: { query: { ie_sexo, ie_ordem_alfabetica }, baseURL: VUE_APP_API_URL },
      });
      commit("setAllSpecialist", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllSpecialistByDate({ rootState, commit, dispatch }, payload) {
    try {
      const response = await obmed.get<{ [key: string]: Specialist[] }>({
        entity: endpoints.byID(
          Number(payload.specialtyID),
          Number(payload.establishmentID || rootState.establishment.current?.id)
        ),
        config: {
          query: {
            dt_consulta: payload.date,
            ie_sexo: payload.ie_sexo,
            ie_ordem_alfabetica: payload.ie_ordem_alfabetica,
          },
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setAllSpecialistByDate", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllSpecialistDate({ rootState, commit, dispatch }, payload) {
    try {
      const response = await obmed.get<string[]>({
        entity: endpoints.dateByID(
          Number(payload.specialtyID),
          Number(payload.establishmentID || rootState.establishment.current?.id)
        ),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setAllSpecialistDate", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const SpecialistStore = { state, getters, modules, mutations, actions };
