<template>
  <svg class="i-notification" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.8981 15.219C18.7712 15.0861 18.6725 14.8615 18.6727 14.6778C18.6632 13.2272 18.6674 11.7794 18.6905 10.3273C18.7168 8.78487 18.6246 7.27481 17.8895 5.86358C17.055 4.26113 15.8388 3.05115 14.18 2.34744C13.371 2.00611 12.5011 1.82186 11.608 1.55162C11.6085 1.38307 11.6129 1.11878 11.6149 0.855299C11.6167 0.421015 11.4002 0.159707 11.0342 0.156278C10.6584 0.153844 10.4302 0.391583 10.4086 0.820266C10.3931 1.09621 10.3898 1.37035 10.3798 1.65451C9.35754 1.76237 8.39612 2.00391 7.50326 2.4784C4.84112 3.89084 3.34563 6.09236 3.19591 9.11283C3.10651 10.9688 3.15242 12.8322 3.1481 14.6908C3.14901 14.92 3.07693 15.0812 2.91218 15.24C2.07505 16.0464 1.24958 16.8639 0.414896 17.6771C0.191524 17.8927 0.0537806 18.1336 0.174368 18.4473C0.299021 18.7723 0.5765 18.8204 0.882431 18.8199C2.98602 18.8176 5.08752 18.8238 7.19074 18.8063C7.50652 18.8049 7.64345 18.8805 7.73902 19.1956C8.39047 21.3027 10.8908 22.1473 12.7133 20.8968C13.4521 20.3911 13.9016 19.6911 14.1137 18.8137C14.223 18.8126 14.3225 18.8125 14.4211 18.8102C16.5573 18.8064 18.6935 18.8026 20.8313 18.8033C21.1524 18.8024 21.4298 18.751 21.5776 18.4249C21.7305 18.0842 21.5354 17.8611 21.313 17.6401C20.5007 16.8434 19.689 16.0413 18.8981 15.219ZM11.0371 20.2581C10.0426 20.3227 9.15542 19.729 8.88696 18.7849C10.2169 18.7805 11.5196 18.7783 12.8268 18.7745C12.7247 19.5255 11.8997 20.2026 11.0371 20.2581Z"
      fill="#CACCCF"
    />
  </svg>
</template>

<style lang="scss">
.i-notification {
  display: flex;
}
</style>
