import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";
import { formatDateEn } from "@/libs/utils";

import { RootState } from "@/store/types";
import {
  User,
  UserState,
  UserLocaleState,
  UserLocaleMutations,
  UserLocaleActions,
  UserLocaleGetters,
  UserProfileForm,
  UserPatient,
  UserForm,
  ObmedServiceCounter,
} from "@/store/user/types";
import { SessionStore } from "./session";
import { AppointmentStore } from "./appointment";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "cadastre-se/",
  signUp: "cadastre-se/",
  byID: (_id: number) => `perfil/${_id}/`,
  userdata: "userdata/",
  forgotPassword: "esqueci-minha-senha/",
  recoverPassword: "esqueci-minha-senha/criar-nova-senha/",
  services: "nossos-servicos/",
  userContactUs: "fale-conosco/",
};

const state: UserLocaleState = {
  current: null,
  patient: null,
  registerForm: null,
  serviceCounter: [],
};

const getters: GetterTree<UserState, RootState> & UserLocaleGetters = {
  userData(state) {
    return state.current;
  },
  patientData(state) {
    return state.patient;
  },
  getObmedServiceCounter(state) {
    return (service) => state.serviceCounter.find((item) => item.servico === service) || null;
  },
};

const mutations: MutationTree<UserState> & UserLocaleMutations = {
  setCurrentUser(state, user) {
    state.current = user;
    if (user) localStorage.setItem("USER_ID", String(user.id));
  },
  setUserRegisterForm(state, form) {
    if (!form) state.registerForm = null;
    else state.registerForm = { ...state.registerForm, ...form };
  },
  setCurrentPatientUser(state, user) {
    state.patient = user;
    if (user) localStorage.setItem("PATIENT_USER_ID", String(user.id));
  },
  setObmedServiceCounter(state, serviceCounter) {
    state.serviceCounter = serviceCounter;
  },
};

const actions: ActionTree<UserState, RootState> & UserLocaleActions = {
  async getUserData({ commit, dispatch }) {
    try {
      const response = await obmed.get<User>({
        entity: endpoints.userdata,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setCurrentUser", response.data || null);
      commit("setCurrentPatientUser", response.data.paciente || null);

      const cartResponse = await dispatch("getCartList");
      commit("setCartList", cartResponse?.data);

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  },
  async createUser({ state, dispatch }) {
    try {
      if (!state.registerForm) return;

      const form: UserForm = JSON.parse(JSON.stringify(state.registerForm));
      if (!form.dt_nascimento.includes("-")) form.dt_nascimento = formatDateEn(form.dt_nascimento);

      const response = await obmed.post<User, UserForm>({
        entity: endpoints.root,
        data: form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false, useToken: false },
      });

      return response;
    } catch (error: any) {
      dispatch("handleError", error);

      return error;
    }
  },
  async editUserProfile({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.patch<UserPatient, Partial<UserProfileForm>>({
        entity: endpoints.byID(Number(localStorage.getItem("PATIENT_USER_ID"))),
        data: form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      commit("setCurrentPatientUser", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async uploadUserProfilePicture({ commit, dispatch }, { form }) {
    try {
      const formData = new FormData();
      formData.append("aq_foto", form.aq_foto);

      const response = await obmed.patch<UserPatient, FormData>({
        entity: endpoints.byID(Number(localStorage.getItem("PATIENT_USER_ID"))),
        data: formData,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      commit("setCurrentPatientUser", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getObmedServiceCounter({ commit }) {
    try {
      const response = await obmed.get<ObmedServiceCounter[]>({
        entity: endpoints.services,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setObmedServiceCounter", response.data || []);

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  },
  async forgotPassword({ commit, dispatch }, payload) {
    try {
      const response = await obmed.post<any>({
        entity: endpoints.forgotPassword,
        data: payload.form,
        config: {
          baseURL: VUE_APP_API_URL,
          useCompany: false,
          useToken: false,
        },
      });

      if (response?.statusText?.toLowerCase() === "ok")
        commit("setToast", [
          {
            summary: response.data.detail || response.data.info,
            severity: "success",
            life: 10000,
          },
        ]);

      return response;
    } catch (error: any) {
      if (error.isObmedError) commit("setToast", [{ summary: "Usuário não encontrado!", severity: "error" }]);
      else dispatch("handleError", error);
    }
  },
  async recoverPassword({ dispatch }, payload) {
    try {
      const response = await obmed.post<any>({
        entity: endpoints.recoverPassword,
        data: payload.form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false, useToken: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async userContactUs({ dispatch }, payload) {
    try {
      const response = await obmed.post({
        entity: endpoints.userContactUs,
        data: payload.form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {
  session: SessionStore,
  appointment: AppointmentStore,
};

export const UserStore = { state, getters, modules, mutations, actions };
