import { RouteRecordRaw } from "vue-router";
import LDefault from "@/layouts/default.vue";

const unauthenticatedRoutes: RouteRecordRaw[] = [
  {
    path: "/esqueci-minha-senha",
    name: "forgot-password",
    component: () => import("@/views/forgot-password/forgot.vue"),
  },
  {
    path: "/forgotmypassword",
    redirect: (to) => ({ name: "recover-password", query: to.query }),
  },
  {
    path: "/redefinir-senha",
    name: "recover-password",
    component: () => import("@/views/forgot-password/recover.vue"),
    beforeEnter: (to) => (!to.query.token ? { name: "root" } : true),
  },
];

const authenticatedRoutes: RouteRecordRaw[] = [
  {
    path: "/carrinho",
    name: "cart",
    meta: { requiresAuth: true },
    component: () => import("@/views/cart/cart.vue"),
  },
  {
    path: "/pagar/paciente/:_id",
    name: "payment",
    meta: { requiresAuth: true },
    component: () => import("@/views/cart/payment.vue"),
    children: [
      {
        path: "/pagar/paciente/:_id/metodos/cartao",
        name: "payment-credit-card",
        component: () => import("@/views/credit-card/form.vue"),
      },
      {
        path: "/pagar/paciente/:_id/metodos/cartao/editar",
        name: "payment-edit-credit-card",
        component: () => import("@/views/credit-card/form.vue"),
      },
      {
        path: "/pagar/paciente/:_id/metodos/boleto",
        name: "payment-boleto",
        component: () => import("@/views/credit-card/form.vue"),
      },
    ],
  },
  {
    path: "/agendamentos",
    name: "appointment",
    meta: { requiresAuth: true },
    component: () => import("@/views/appointment/appointment.vue"),
    beforeEnter(to) {
      if (!to.query.ie_status) return { ...to, query: { ...to.query, ie_status: "A" } };
      return true;
    },
    children: [
      {
        path: "/agendamentos/filtrar",
        name: "appointment-filter",
        component: () => import("@/views/appointment/filter.vue"),
      },
      {
        path: "/agendamentos/:_id/observacoes",
        name: "appointment-observation",
        beforeEnter(to) {
          if (!Number(to.params._id) || Number(to.params._id) < 1) return { name: "appointment" };
          return true;
        },
        component: () => import("@/views/appointment/observation.vue"),
      },
    ],
  },
  {
    path: "/agendamentos/:_id/reagendar",
    name: "appointment-reschedule",
    meta: { requiresAuth: true },
    component: () => import("@/views/appointment/reschedule.vue"),
    children: [
      {
        path: "/agendamentos/:_id/reagendar/confirmar",
        name: "appointment-reschedule-confirm",
        component: () => import("@/views/appointment/confirm.vue"),
      },
    ],
  },
  {
    path: "/agendar/consulta",
    name: "consultation",
    meta: { requiresAuth: true },
    component: () => import("@/views/consultation/consultation.vue"),
    children: [
      {
        path: "/agendar/consulta/:patient/especialidade",
        name: "consultation-specialty",
        component: () => import("@/views/consultation/specialty.vue"),
      },
      {
        path: "/agendar/consulta/:patient/especialista",
        name: "consultation-specialist",
        component: () => import("@/views/consultation/specialist.vue"),
        children: [
          {
            path: "/agendar/consulta/:patient/:_id/especialidade",
            name: "consultation-specialist-specialty",
            component: () => import("@/views/consultation/select-specialty.vue"),
          },
        ],
      },
      {
        path: "/agendar/consulta/:patient/:specialty/estabelecimento",
        name: "consultation-establishment",
        component: () => import("@/views/consultation/establishment.vue"),
      },
      {
        path: "/agendar/consulta/:patient/:specialty/:establishment/especialista",
        name: "consultation-specialty-specialist",
        component: () => import("@/views/consultation/specialist.vue"),
      },
      {
        path: "/agendar/consulta/:patient/:specialty/:establishment/:specialist",
        name: "consultation-add",
        component: () => import("@/views/consultation/add.vue"),
      },
    ],
  },
  {
    path: "/agendar/orcamento",
    name: "budget",
    meta: { requiresAuth: true },
    component: () => import("@/views/budget/budget.vue"),
    children: [
      {
        path: "/agendar/orcamento/estabelecimento",
        name: "budget-establishment",
        component: () => import("@/views/budget/establishment.vue"),
      },
    ],
  },
  {
    path: "/agendar/procedimento",
    name: "procedure",
    meta: { requiresAuth: true },
    component: () => import("@/views/procedure/procedure.vue"),
    children: [
      {
        path: "/agendar/procedimento/:patient/estabelecimento",
        name: "procedure-establishment",
        component: () => import("@/views/procedure/establishment.vue"),
      },
      {
        path: "/agendar/procedimento/:patient/:establishment",
        name: "procedure-add",
        component: () => import("@/views/procedure/add.vue"),
      },
    ],
  },
  {
    path: "/agendar/exame",
    name: "exam-options",
    meta: { requiresAuth: true },
    component: () => import("@/views/exam/exam.vue"),
  },
  {
    path: "/agendar/exame-imagem",
    name: "exam",
    meta: { requiresAuth: true },
    component: () => import("@/views/exam/image/image.vue"),
    children: [
      {
        path: "/agendar/exame-imagem/:patient/estabelecimento",
        name: "exam-establishment",
        component: () => import("@/views/exam/image/establishment.vue"),
      },
      {
        path: "/agendar/exame-imagem/:patient/:establishment",
        name: "exam-add",
        component: () => import("@/views/exam/image/add.vue"),
      },
    ],
  },
  {
    path: "/agendar/exame-laboratorial",
    name: "laboratory",
    meta: { requiresAuth: true },
    component: () => import("@/views/exam/laboratory/laboratory.vue"),
    children: [
      {
        path: "/agendar/exame-laboratorial/:patient/estabelecimento",
        name: "laboratory-establishment",
        component: () => import("@/views/exam/laboratory/establishment.vue"),
      },
      {
        path: "/agendar/exame-laboratorial/:patient/:establishment",
        name: "laboratory-add",
        component: () => import("@/views/exam/laboratory/add.vue"),
      },
    ],
  },
  {
    path: "/perfil",
    name: "profile",
    meta: { requiresAuth: true },
    component: () => import("@/views/profile/profile.vue"),
    redirect: { name: "profile-personal" },
    children: [
      {
        path: "/perfil/dados-pessoais",
        name: "profile-personal",
        component: () => import("@/views/profile/personal.vue"),
      },
      {
        path: "/perfil/endereco",
        name: "profile-address",
        component: () => import("@/views/profile/localization.vue"),
      },
    ],
  },
  {
    path: "/meu-historico",
    name: "historic",
    meta: { requiresAuth: true },
    component: () => import("@/views/historic/historic.vue"),
    children: [
      {
        path: "/meu-historico/filtrar",
        name: "historic-filter",
        component: () => import("@/views/historic/filter.vue"),
      },
      {
        path: "/meu-historico/:_id/observacoes",
        name: "historic-observation",
        beforeEnter(to) {
          if (!Number(to.params._id) || Number(to.params._id) < 1) return { name: "historic" };
          return true;
        },
        component: () => import("@/views/historic/observation.vue"),
      },
    ],
  },
  {
    path: "/meu-faturamento/:type",
    name: "revenues",
    meta: { requiresAuth: true },
    component: () => import("@/views/revenues.vue"),
  },
];

export default [
  {
    path: "/",
    name: "root",
    component: LDefault,
    redirect: { name: "home" },
    children: [
      {
        path: "/",
        name: "home",
        alias: "/home",
        component: () => import("@/views/home/home.vue"),
        children: [
          {
            path: "/entrar",
            name: "login",
            meta: { requiresNotLogged: true },
            component: () => import("@/views/login/login.vue"),
          },
          {
            path: "/criar-conta",
            name: "register",
            redirect: { name: "register-login" },
          },
          {
            path: "/criar-conta/login",
            name: "register-login",
            component: () => import("@/views/register/login.vue"),
          },
          {
            path: "/criar-conta/dados-pessoais",
            name: "register-personal",
            component: () => import("@/views/register/personal.vue"),
          },
          {
            path: "/criar-conta/endereco",
            name: "register-address",
            component: () => import("@/views/register/address.vue"),
          },
          {
            path: "/criar-conta/finalizar",
            name: "register-finish",
            component: () => import("@/views/register/register.vue"),
          },
        ],
      },
      ...unauthenticatedRoutes,
      ...authenticatedRoutes,
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "page-not-found",
    component: () => import("@/layouts/not-found.vue"),
  },
];
