import { ActionTree, GetterTree, MutationTree } from "vuex";
import obmed, { ObmedCancelRequest } from "@/services/obmed";

import { RootState } from "@/store/types";
import {
  AppointmentActions,
  AppointmentGetters,
  AppointmentMutations,
  AppointmentState,
  Appointment,
} from "@/store/user/appointment/types";
import { Patient } from "@/store/patient/types";

import { compareDates } from "@/libs/utils";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "parceiro-historico-de-venda/",
  guia: "imprimir-guia-eletronica/",
  byID: (_id: number) => `agendamento/${_id}/reagendar/`,
};

const cancelRequests: { [key: string]: ObmedCancelRequest | null } = {
  search: null,
};

function groupAppointmentsByPatient(appointments: Appointment[]) {
  const patients: Patient[] = [];
  const patientIDs: number[] = [];

  appointments.forEach((item) => {
    if (item.cd_paciente?.id && !patientIDs.includes(item.cd_paciente.id)) {
      patients.push(item.cd_paciente);
      patientIDs.push(Number(item.cd_paciente.id));
    }
  });

  return patients
    .map((item) => ({ ...item, items: appointments.filter((subitem) => subitem.cd_paciente?.id === item.id) }))
    .map((item) => ({
      ...item,
      executedValue: item.items
        .filter((item) => item.ie_status === "EX")
        .reduce((total, subitem) => total + (subitem.nr_valor || 0), 0),
      pendingValue: item.items
        .filter((item) => item.ie_status !== "EX")
        .reduce((total, subitem) => total + (subitem.nr_valor || 0), 0),
    }));
}

const state: AppointmentState = {
  list: [],
  grouped: [],
  searchList: [],
};

const getters: GetterTree<AppointmentState, RootState> & AppointmentGetters = {
  appointmentsGrouped(state) {
    return groupAppointmentsByPatient(state.list);
  },
  pastAppointmentsGrouped(state) {
    const dateObj = new Date();
    const formatMoth = (value: number) => `0${value + 1}`.slice(-2);
    const date = `${dateObj.getFullYear()}-${formatMoth(dateObj.getMonth())}-01`;

    return groupAppointmentsByPatient(state.list.filter((appointment) => !compareDates(appointment.dt_agenda, date)));
  },
  currentAppointmentsGrouped(state) {
    const dateObj = new Date();
    const formatMoth = (value: number) => `0${value + 1}`.slice(-2);
    const date = `${dateObj.getFullYear()}-${formatMoth(dateObj.getMonth())}-01`;

    return groupAppointmentsByPatient(state.list.filter((appointment) => compareDates(appointment.dt_agenda, date)));
  },
};

const mutations: MutationTree<AppointmentState> & AppointmentMutations = {
  setAppointments(state, appointment) {
    state.list = appointment;
  },
  setSearchAppointments(state, appointments) {
    state.searchList = appointments;
  },
  setGroupedAppointments(state, appointments) {
    state.grouped = groupAppointmentsByPatient(appointments);
  },
};

const actions: ActionTree<AppointmentState, RootState> & AppointmentActions = {
  async getAppointments({ commit, dispatch }) {
    try {
      const response = await obmed.get<Appointment[]>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL },
      });

      commit("setAppointments", response.data);
      commit("setGroupedAppointments", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getElectronicGuide({ dispatch }, payload) {
    try {
      const response = await obmed.get<any>({
        entity: endpoints.guia,
        config: {
          query: { id_guia_eletronica: payload._id },
          baseURL: VUE_APP_API_URL,
        },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async rescheduleAppointment({ dispatch }, { _id, cd_horario_novo }) {
    try {
      const response = await obmed.patch<any>({
        entity: endpoints.byID(_id),
        data: { cd_horario_novo },
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchAppointments({ commit, dispatch }, { search, isPrimary }) {
    try {
      if (cancelRequests.search) cancelRequests.search.cancel();
      cancelRequests.search = obmed.getCancelToken();

      if (search?.ie_status === "NA") search.ie_status = "A";

      const response = await obmed.get<Appointment[]>({
        entity: endpoints.root,
        config: {
          cancelToken: cancelRequests.search?.token,
          query: { ...search },
          baseURL: VUE_APP_API_URL,
        },
      });

      commit("setSearchAppointments", response.data || []);

      if (isPrimary) {
        commit("setAppointments", response.data || []);
        commit("setGroupedAppointments", response.data || []);
      }

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

export const AppointmentStore = { state, mutations, getters, actions };
