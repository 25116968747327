import obmed, { ObmedCancelRequest } from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { PaginationResponse, RootState } from "@/store/types";
import {
  Laboratory,
  LaboratoryState,
  LaboratoryLocaleState,
  LaboratoryLocaleMutations,
  LaboratoryLocaleActions,
  LaboratoryLocaleGetters,
} from "@/store/laboratory/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "consulta-exame-procedimento-cirurgia/?ie_tipo_procedimento=EL&agenda=1&group=1",
  byID: (_id: number) => `consulta-exame-procedimento-cirurgia/${_id}/`,
};

const cancelRequests: { [key: string]: ObmedCancelRequest | null } = {
  search: null,
};

const state: LaboratoryLocaleState = {
  list: {},
};

const getters: GetterTree<LaboratoryState, RootState> & LaboratoryLocaleGetters = {
  laboratoryList(state) {
    return Object.keys(state.list).reduce<Laboratory[]>((list, page) => [...list, ...state.list[page]], []);
  },
};

const mutations: MutationTree<LaboratoryState> & LaboratoryLocaleMutations = {
  setAllLaboratory(state, { page, laboratory }) {
    state.list[page] = laboratory;
  },
};

const actions: ActionTree<LaboratoryState, RootState> & LaboratoryLocaleActions = {
  async searchLaboratory({ commit, dispatch }, { page, search }) {
    try {
      if (cancelRequests.search) cancelRequests.search.cancel();
      cancelRequests.search = obmed.getCancelToken();

      const response = await obmed.get<PaginationResponse<Laboratory>>({
        entity: endpoints.root,
        config: {
          query: { page, nm_procedimento: search },
          cancelToken: cancelRequests.search?.token,
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setAllLaboratory", { page, laboratory: response.data.result });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const LaboratoryStore = { state, getters, modules, mutations, actions };
