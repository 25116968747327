import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Patient,
  PatientState,
  PatientLocaleState,
  PatientLocaleMutations,
  PatientLocaleActions,
  PatientLocaleGetters,
  PatientForm,
} from "@/store/patient/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "paciente/",
  byID: (_id: number | string) => `paciente/${_id}/`,
};

const state: PatientLocaleState = {
  current: null,
  list: [],
  searchList: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<PatientState, RootState> & PatientLocaleGetters = {};

const mutations: MutationTree<PatientState> & PatientLocaleMutations = {
  setPatient(state, patient) {
    state.current = patient;
  },
  setPatients(state, patients) {
    state.list = patients;
  },
  setSearchPatients(state, patients) {
    state.searchList = patients;
  },
  setPatientPagination(state, pagination) {
    state.pagination = pagination;
  },
  setPatientPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<PatientState, RootState> & PatientLocaleActions = {
  async getPatient({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<Patient>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setPatient", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getPatients({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<Patient>>({
        entity: endpoints.root,
        config: { query: { ...pagination, titular: true }, baseURL: VUE_APP_API_URL },
      });

      commit("setPatients", response.data?.results || []);
      commit("setPatientPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createPatient({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<any>({
        entity: endpoints.root,
        data: { cd_estabelecimento: Number(localStorage.getItem("CD_ESTABELECIMENTO")), ...form },
        config: { query: { titular: true }, baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Cadastro realizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updatePatient({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<any, PatientForm>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Dados atualizados com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchPatients({ state, commit, dispatch }, { search, isHolder, isPrimary }) {
    try {
      const pagination: PaginationParams = { page: 1, per_page: 25, paginacao: true };
      const response = await obmed.get<PaginationResponse<Patient>>({
        entity: endpoints.root,
        config: { query: { titular: isHolder, ...search, ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setSearchPatients", response.data?.results || []);

      if (isPrimary) {
        commit("setPatients", response.data?.results || []);
        commit("setPatientPagination", { ...state.pagination, items: response.data.count });
      }

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const PatientStore = { state, getters, modules, mutations, actions };
