import obmed, { ObmedCancelRequest } from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { PaginationResponse, RootState } from "@/store/types";
import {
  Exam,
  ExamState,
  ExamLocaleState,
  ExamLocaleMutations,
  ExamLocaleActions,
  ExamLocaleGetters,
} from "@/store/exam/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "consulta-exame-procedimento-cirurgia/?ie_tipo_procedimento=EC&page=1&group=1",
};

const cancelRequests: { [key: string]: ObmedCancelRequest | null } = {
  search: null,
};

const state: ExamLocaleState = {
  list: [],
};

const getters: GetterTree<ExamState, RootState> & ExamLocaleGetters = {};

const mutations: MutationTree<ExamState> & ExamLocaleMutations = {
  setAllExam(state, exam) {
    state.list = exam;
  },
};

const actions: ActionTree<ExamState, RootState> & ExamLocaleActions = {
  async searchExam({ commit, dispatch }, { search }) {
    try {
      if (cancelRequests.search) cancelRequests.search.cancel();
      cancelRequests.search = obmed.getCancelToken();

      const response = await obmed.get<PaginationResponse<Exam>>({
        entity: endpoints.root,
        config: {
          query: { nm_procedimento: search },
          cancelToken: cancelRequests.search?.token,
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setAllExam", response.data.result);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const ExamStore = { state, getters, mutations, actions, modules };
